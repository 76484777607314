import {
  Injectable,
  InjectionToken,
  Injector,
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/core';

import { PlatformService } from '@shared/services/platform.service';

interface TransferableValue<T> {
  injectionToken: InjectionToken<T>;
  transferStateKey: StateKey<T>;
  cachedValue?: T;
}

const transferableValues = ['YANDEX_METRIKA_COUNTER_ID'] as const;
type TransferableValues = (typeof transferableValues)[number];

@Injectable()
export class TransferableValuesService {
  static readonly YANDEX_METRIKA_COUNTER_ID =
    TransferableValuesService.createTransferableValue<number>(
      'YANDEX_METRIKA_COUNTER_ID'
    );

  constructor(
    private injector: Injector,
    private platform: PlatformService,
    private transferState: TransferState
  ) {}

  transferValuesFromSSRToClient() {
    if (this.platform.isBrowser)
      throw new Error(
        'Метод transferValuesFromSSRToClient создан для вызова из фабрики для APP_INITIALIZER на стороне SSR.'
      );

    for (const transferableValue of transferableValues) {
      const transferValue =
        TransferableValuesService.getTransferValue(transferableValue);
      const injectedValue = this.getFromInjector(transferValue);
      if (injectedValue !== undefined) {
        this.transferToClient(transferableValue, injectedValue);
      }
    }
  }

  getValue<T>(valueName: TransferableValues, defaultValue: T): T {
    const transferValue =
      TransferableValuesService.getTransferValue<T>(valueName);
    const value =
      transferValue.cachedValue ??
      (this.platform.isBrowser
        ? this.getFromTransferState(transferValue, defaultValue)
        : this.getFromInjector(transferValue, defaultValue));
    if (transferValue.cachedValue === undefined) {
      transferValue.cachedValue = value;
    }
    return value;
  }

  static provide<T>(valueName: TransferableValues, value: T) {
    return {
      provide: this.getTransferValue<T>(valueName).injectionToken,
      useValue: value,
    };
  }

  private getFromInjector<T>(
    transferValue: TransferableValue<T>,
    defaultValue?: T
  ): T {
    try {
      return this.injector.get(transferValue.injectionToken);
    } catch (err) {
      console.warn(
        `Нужно инъектировать значение для ${transferValue.injectionToken}. Пока вместо него будет использоваться ${defaultValue}.`,
        err
      );
      return defaultValue as T;
    }
  }

  private getFromTransferState<T>(
    transferValue: TransferableValue<T>,
    defaultValue: T
  ) {
    const key = transferValue.transferStateKey;
    if (!this.transferState.hasKey(key)) {
      console.warn(
        `Ключ ${key} не найден в TransferState. Проверьте, инъектировано ли значение, и был ли корректно вызван перенос значения с SSR на клиент.`
      );
    }
    return this.transferState.get(key, defaultValue);
  }

  private transferToClient<T>(valueName: TransferableValues, value: T): void {
    this.transferState.set(
      TransferableValuesService.getTransferValue(valueName).transferStateKey,
      value
    );
  }

  private static getTransferValue<T>(
    name: TransferableValues
  ): TransferableValue<T> {
    const prop = Object.getOwnPropertyDescriptor(
      TransferableValuesService,
      name
    );
    if (!prop)
      throw new Error(
        `Свойство ${name} отсутствует в TransferableValuesService.`
      );
    return prop.value as TransferableValue<T>;
  }

  private static createTransferableValue<T>(
    name: TransferableValues
  ): TransferableValue<T> {
    return {
      injectionToken: new InjectionToken<T>(name),
      transferStateKey: makeStateKey<T>(name),
    };
  }
}
