import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ModalSize } from '@shared/enums/modal-sizes';
import { ModalChangeCityWarningComponent } from '@shared/components/modal-change-city-warning/modal-change-city-warning.component';
import { AddCartItemService } from '@shared/modules/add-cart-item/services/add-cart-item.service';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  first,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ModalService } from './modal/modal.service';
import { PlatformService } from './platform.service';
import { StorageService } from '@shared/core/services/storage.service';
import { CitiesClient, CityResponse } from '@medindex-webapi';
import { ChangeCityService } from './changeCity.service';
import { HelixRoutes } from '@shared/enums/routes';
import { PRERENDERED_ROUTES } from 'src/environments/prerendered-routes.const';

/**
 * Сервис с методами для первичной простановки города.
 */
@Injectable({
  providedIn: 'root',
})
export class CityInitializerService {
  constructor(
    private router: Router,
    private citiesClient: CitiesClient,
    private platformService: PlatformService,
    private storage: StorageService,
    private modal: ModalService,
    private addCartItemService: AddCartItemService,
    private changeCityService: ChangeCityService
  ) {}

  /** Хранит алиас из первичного роута. */
  routeAlias?: string;
  /** Хранит город, полученный по алиасу. */
  cityByAlias?: CityResponse;
  /** Был ли ранее сохранен город в браузере. */
  hasCurrentCity = false;
  /** Ранее сохраненный в браузере город. */
  cityFromStorage?: CityResponse;

  /**
   * Шаг инициализации города на этапе APP_INITIALIZER.
   *
   * Применяем к вьюшкам город по алиасу из URL
   * (если алиас некорректный, перенаправляем на страницу ошибки и ставим текущий город).
   * На этом этапе не происходит установка города в localStorage и куках, только применяем для отображения.
   * @returns Observable.
   */
  appInitializerPart(): Observable<any> {
    const layoutViewData = this.storage.getLayoutViewDataCache();
    this.hasCurrentCity = layoutViewData !== this.storage.defaultViewData;
    this.cityFromStorage = layoutViewData.currentCity;

    if (this.hasCurrentCity) {
      // проставляем город из local storage
      this.storage.setCity(this.cityFromStorage, false);
    }

    return this.getAliasFromRoute().pipe(
      switchMap((routeAlias) =>
        this.citiesClient
          .getCurrentUserCity(routeAlias)
          .pipe(withLatestFrom(of(routeAlias)))
      ),
      catchError(() => {
        console.debug('Failed to get city by alias');
        // несуществующий алиас, направляем на страницу 404
        this.router.navigateByUrl(`/${HelixRoutes.Error}`);
        // и берем текущий город
        return this.citiesClient.getCurrentUserCity(undefined).pipe(
          withLatestFrom(of(undefined)),
          catchError(() => {
            console.debug('Get current city finished with error');
            return EMPTY;
          })
        );
      }),
      tap(([cityByAlias, routeAlias]) => {
        this.cityByAlias = cityByAlias;
        this.routeAlias = routeAlias;
      }),
      tap(([cityByAlias]) =>
        // применяем к приложению город по алиасу (пока без сохранения в localStorage)
        this.storage.setCity(cityByAlias, false)
      )
    );
  }

  /**
   * Шаг инициализации города на этапе старта корневого компонента приложения.
   * (пришлось выделить из-за проблем с внутрянкой ангуляра при вызове модалок из APP_INITIALIZER)
   *
   * Тут решается, сменить ли окончательно город на указанный в URL, или переприменить текущий.
   * (выполняется только на стороне браузера)
   * @returns Observable.
   */
  rootComponentStartPart(): Observable<string> {
    if (this.platformService.isServer) return of('not changing city on SSR');

    const gotHiddenCityNotByDirectLinkAndPrevCityWasNotHidden =
      !this.routeAlias &&
      this.cityByAlias?.isVisibleForCustomers === false &&
      (!this.hasCurrentCity || this.cityFromStorage!.isVisibleForCustomers);
    if (gotHiddenCityNotByDirectLinkAndPrevCityWasNotHidden) {
      return this.changeCityService
        .changeCity({
          id: 2,
          name: 'Санкт-Петербург',
          alias: '',
        })
        .pipe(
          catchError(() => of(undefined)),
          tap(() => this.changeCityService.setShowSuggestCity(true)),
          switchMap(() => this.changeCityService.showSuggestCityModal())
        );
    }

    const newCity = this.cityByAlias
      ? {
          id: this.cityByAlias.id,
          name: this.cityByAlias.name,
          alias: this.cityByAlias.alias,
        }
      : undefined;

    return this.shouldApplyNewCity(newCity?.alias, newCity?.name).pipe(
      switchMap((shouldApplyCity) => {
        return shouldApplyCity && newCity
          ? this.changeCityService.changeCity(newCity)
          : this.changeCityService.setCurrentCity();
      }),
      catchError(() => of(undefined)),
      switchMap(() => this.changeCityService.showSuggestCityModal())
    );
  }

  /** Получает алиас города и URL из роута. */
  private getAliasFromRoute(): Observable<string | undefined> {
    return this.router.events.pipe(
      filter(
        (event): event is RoutesRecognized => event instanceof RoutesRecognized
      ),
      first(),
      switchMap((event) => {
        if (this.shouldSkipPrerenderedRoute(event.state.url)) {
          return EMPTY;
        }
        return of(
          event.state.root.firstChild?.params?.cityAlias as string | undefined
        );
      })
    );
  }

  /**
   * Пропускать ли логику регионализации для текущего роута.
   *
   * (регионализация ломает пререндер, но для таких страниц она не применяется,
   * поэтому можем отключить)
   * @param currentUrl Текущий URL.
   * @returns true - пропустить ли логику регионализации, иначе false.
   */
  private shouldSkipPrerenderedRoute(currentUrl: string): boolean {
    return PRERENDERED_ROUTES.some((prerenderedRoute) =>
      currentUrl.includes(prerenderedRoute)
    );
  }

  /**
   * Решает, нужно ли применять новый город
   * (при необходимости запрашивает решение у пользователя через попап).
   * @param newCityAlias Алиас нового города для простановки.
   * @param newCityName Имя нового города для простановки.
   * @returns Observable с флагом, нужно ли применять новый город или нет.
   */
  private shouldApplyNewCity(
    newCityAlias?: string | null,
    newCityName?: string | null
  ): Observable<boolean> {
    const isRouteAliasNotEmpty = !!this.routeAlias;
    const isAnotherCity =
      !this.hasCurrentCity || this.cityFromStorage?.alias != newCityAlias;

    if (isRouteAliasNotEmpty && isAnotherCity) {
      const oldCityName = this.hasCurrentCity
        ? this.cityFromStorage?.name
        : undefined;
      return this.isCartFull().pipe(
        switchMap((isCartFull) =>
          isCartFull
            ? this.askCityChangeConfirmation(oldCityName, newCityName)
            : of(true)
        )
      );
    }

    return of(false);
  }

  /** Отслеживает первое обновление корзины и говорит, наполнена ли корзина. */
  private isCartFull(): Observable<boolean> {
    return this.addCartItemService.isInitialized$.pipe(
      filter((isInitialized) => isInitialized),
      switchMap(() => this.addCartItemService.hxids$),
      map((hxids) => !!hxids.length),
      first()
    );
  }

  /**
   * Открывает модалку подтверждения смены города.
   * @returns Observable с ответом пользователя.
   */
  private askCityChangeConfirmation(
    oldCityName?: string | null,
    newCityName?: string | null
  ): Observable<boolean> {
    const confirmationResult$ = new Subject<boolean>();
    this.modal.create({
      nzTitle: 'Подтверждение',
      nzContent: ModalChangeCityWarningComponent,
      nzComponentParams: {
        isDummy: true,
        oldCityName,
        newCityName,
      },
      nzStyle: {
        width: ModalSize.Warning,
      },
      nzFooter: null,
      nzOnOk: () => confirmationResult$.next(true),
      nzOnCancel: () => confirmationResult$.next(false),
    });
    return confirmationResult$.asObservable();
  }
}
