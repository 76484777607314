import {
  HTTP_INTERCEPTORS,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Injectable,
  NgModule,
  StateKey,
  makeStateKey,
  TransferState,
} from '@angular/core';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { PlatformService } from '@shared/services/platform.service';

interface WithStore {
  /**
   * In Angular 16 (see {@link https://github.com/angular/angular/blob/16.2.12/packages/core/src/transfer_state.ts#L85})
   * this property is internal and there is no method to enumerate it,
   * so we use casting to this interface in order to get access to it to retrieve stateKeys
   * for removing cached request results for APIs that we don't want to be cached on SSR.
   */
  store: Record<string, unknown | undefined>;
}

@Injectable()
export class HttpCacheSkipInterceptor implements HttpInterceptor {
  /** API, которые мы не хотим брать из TransferState кэша, пришедшего с SSR. */
  private readonly apisToSkipCache: string[] = ['api/cart/'];

  constructor(
    private transferState: TransferState,
    private platform: PlatformService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.platform.isBrowser && this.shouldSkipCacheFor(req.url)) {
      this.getCacheKeysToRemove().forEach((cacheKey) =>
        this.transferState.remove(cacheKey)
      );
    }
    return next.handle(req);
  }

  private shouldSkipCacheFor(it: string): boolean {
    return this.apisToSkipCache.some((apiToSkip) => it.includes(apiToSkip));
  }

  private getCacheKeysToRemove(): StateKey<unknown>[] {
    return Object.keys((this.transferState as unknown as WithStore).store)
      .filter((key) => this.shouldSkipCacheFor(key))
      .map((key) => makeStateKey<unknown>(key));
  }
}

@NgModule({
  providers: [
    TransferState,
    HttpCacheSkipInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: HttpCacheSkipInterceptor,
      multi: true,
    },
  ],
})
export class TransferHttpCacheSkipModule {}

@NgModule({
  // порядок импорта важен
  imports: [TransferHttpCacheSkipModule, TransferHttpCacheModule],
})
export class TransferHttpCacheWithSkippingModule {}
