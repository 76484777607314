import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import ru from '@angular/common/locales/ru';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import ruDateLocale from 'date-fns/locale/ru';
import {
  NZ_I18N,
  ru_RU,
  NZ_DATE_CONFIG,
  NZ_DATE_LOCALE,
} from 'ng-zorro-antd/i18n';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';

import {
  CatalogClient,
  CitiesClient,
  ProfileClient,
  UserAccountClient,
} from '@medindex-webapi';
import { HeaderModule } from '@shared/modules/header/header.module';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { DatasourceService } from '@shared/core/services/datasource.service';
import { ErrorService } from '@shared/core/services/error.service';
import { StorageService } from '@shared/core/services/storage.service';
import { ErrorInterceptor } from '@shared/interceptors/error.interceptor';
import { ConfigurationService } from '@shared/services/configuration.service';
import { ValidationService } from '@shared/services/validation/validation.service';
import { AddCartItemModule } from '@shared/modules/add-cart-item/add-cart-item.module';
import { ValidationRulesService } from '@shared/services/validation/validation-rules.service';
import { ServiceUnavailableModalModule } from '@shared/modules/service-unavailable-modal/service-unavailable-modal.module';
import { ModalService } from '@shared/services/modal/modal.service';
import { InitializerService } from '@shared/services/initializer.service';
import { ErrorMapService } from '@shared/modules/map/services/error-map.service';
import { VisuallyImpairedModule } from './modules/visually-impaired/visually-impaired.module';
import { yaMapConfig } from '@shared/services/configuration.service';
import { MetaService } from '@shared/services/meta/meta.service';
import { PlatformService } from '@shared/services/platform.service';
import { ChangeCityService } from '@shared/services/changeCity.service';
import { TransferHttpCacheWithSkippingModule } from '@shared/interceptors/http-cache.interceptor';
import { MobileBottomNavigationComponent } from '@shared/components/mobile-bottom-navigation/mobile-bottom-navigation.component';
import { LowerCaseUrlSerializer } from '@shared/url-serializers/lower-case.urlserializer';
import { YmService } from '@shared/services/yandex-metrica/ym.service';
import { ReportErrorModalService } from '@shared/components/report-error-modal/services/report-error-modal.service';
import { WriteToHelixModalService } from '@shared/components/write-to-helix-modal/services/write-to-helix-modal.service';
import { PageScrollingService } from '@shared/services/page-scrolling';
import { NavigationMenuService } from '@shared/services/navigation-menu.service';
import { UserInfoService } from '@shared/modules/header/services/user-info.service';
import { CssVariablesService } from '@shared/services/css-variables.service';
import { TransferableValuesService } from '@shared/services/transferable-values.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { provideYConfig } from 'angular-yandex-maps-v3';

export function initCity(initializer: InitializerService) {
  return () => initializer.setCurrentCity();
}

export function initConfiguration(initializer: InitializerService) {
  return () => initializer.setConfiguration();
}

registerLocaleData(ru);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AddCartItemModule,
    HeaderModule,
    ServiceUnavailableModalModule,
    FooterComponent,
    AngularYandexMapsModule.forRoot(yaMapConfig),
    TransferHttpCacheWithSkippingModule,
    VisuallyImpairedModule,
    MobileBottomNavigationComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    CitiesClient,
    InitializerService,
    ChangeCityService,
    NzModalService,
    ConfigurationService,
    StorageService,
    UserAccountClient,
    ProfileClient,
    UserInfoService,
    CatalogClient,
    NavigationMenuService,
    DatasourceService,
    ValidationService,
    ValidationRulesService,
    ErrorService,
    ErrorMapService,
    ModalService,
    TransferState,
    MetaService,
    PlatformService,
    TransferableValuesService,
    YmService,
    ReportErrorModalService,
    WriteToHelixModalService,
    PageScrollingService,
    CssVariablesService,
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initCity,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    {
      provide: NZ_DATE_LOCALE,
      useValue: ruDateLocale,
    },
    {
      provide: NZ_I18N,
      useValue: ru_RU,
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    provideYConfig(yaMapConfig),
  ],
})
export class AppModule {}
