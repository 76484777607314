import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PlatformService } from '@shared/services/platform.service';
import { YmHitOptions } from '@shared/services/yandex-metrica/interfaces/ym-hit.interfaces';
import { YmReachGoalOptions } from '@shared/services/yandex-metrica/interfaces/ym-reach-goal.interfaces';
import { TransferableValuesService } from '@shared/services/transferable-values.service';

@Injectable()
export class YmService {
  clientID$ = new Observable<string>((subscriber) => {
    try {
      this.ym('getClientID', function (clientID) {
        subscriber.next(clientID);
        subscriber.complete();
      });
    } catch (err) {
      subscriber.error(err);
    }
  });

  counterID = this.transfer.getValue('YANDEX_METRIKA_COUNTER_ID', -1);

  constructor(
    private platform: PlatformService,
    private transfer: TransferableValuesService
  ) {}

  hit(): void {
    this.ym('hit');
  }

  reachGoal(target: string, options?: Partial<YmReachGoalOptions>): void {
    this.ym('reachGoal', target, options);
  }

  private ym(type: 'hit', url?: string, options?: Partial<YmHitOptions>): void;
  private ym(
    type: 'reachGoal',
    target: string,
    options: Partial<YmReachGoalOptions> | undefined
  ): void;
  private ym(
    type: 'getClientID',
    callbackWithClientID: (clientID: string) => void
  ): void;

  private ym(...args: any): void {
    if (this.platform.isServer) return;

    if (this.counterID) {
      window.ym(this.counterID, ...args);
    }
  }
}
